// Generated by Framer (3def70c)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import StatusPulse from "https://framerusercontent.com/modules/0Tnyq0i1MGPjEtIRog8t/5BV5V2nEcXHU7NyVRp43/WM8Gskec6.js";
import * as sharedStyle from "../css/ro7OPezbn";
import * as localizedValues from "./LuuHMz_1U-0.js";
const StatusPulseFonts = getFonts(StatusPulse);

const cycleOrder = ["cX0MpctAV", "GlS3sY0RA"];

const serializationHash = "framer-hsqPn"

const variantClassNames = {cX0MpctAV: "framer-v-cl84rh", GlS3sY0RA: "framer-v-x0p6i9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Footer: "cX0MpctAV", Nav: "GlS3sY0RA"}

const getProps = ({height, hideStatus, id, width, ...props}) => { return {...props, AgMT4QNLZ: hideStatus ?? props.AgMT4QNLZ ?? true, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "cX0MpctAV"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;hideStatus?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, fQoJEScDb, AgMT4QNLZ, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "cX0MpctAV", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-cl84rh", className, classNames)} data-framer-name={"Footer"} layoutDependency={layoutDependency} layoutId={"cX0MpctAV"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({GlS3sY0RA: {"data-framer-name": "Nav"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNjAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "600", "--framer-letter-spacing": "0px", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-d21ac01e-dce3-4822-b3f1-b0798f091443, rgb(84, 84, 84)))"}}><Link href={{webPageId: "pqeo6ckm_"}} openInNewTab={false} smoothScroll={false}><motion.a className={"framer-styles-preset-1wicq5s"} data-styles-preset={"ro7OPezbn"}>Status</motion.a></Link></motion.p></React.Fragment>} className={"framer-162hfqf"} fonts={["GF;Inter-600"]} layoutDependency={layoutDependency} layoutId={"HbynJ0hsi"} style={{"--extracted-r6o4lv": "var(--token-d21ac01e-dce3-4822-b3f1-b0798f091443, rgb(84, 84, 84))"}} verticalAlignment={"top"} withExternalLayout/>{AgMT4QNLZ && (<ComponentViewportProvider width={"16px"}><motion.div className={"framer-11gbeyb-container"} layoutDependency={layoutDependency} layoutId={"s_1cZIPlF-container"}><StatusPulse height={"100%"} id={"s_1cZIPlF"} layoutId={"s_1cZIPlF"} style={{height: "100%", width: "100%"}} variant={fQoJEScDb} width={"100%"}/></motion.div></ComponentViewportProvider>)}</motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hsqPn.framer-11eyz0k, .framer-hsqPn .framer-11eyz0k { display: block; }", ".framer-hsqPn.framer-cl84rh { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 4px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-hsqPn .framer-162hfqf { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-hsqPn .framer-11gbeyb-container { flex: none; height: 16px; position: relative; width: 16px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-hsqPn.framer-cl84rh { gap: 0px; } .framer-hsqPn.framer-cl84rh > * { margin: 0px; margin-left: calc(4px / 2); margin-right: calc(4px / 2); } .framer-hsqPn.framer-cl84rh > :first-child { margin-left: 0px; } .framer-hsqPn.framer-cl84rh > :last-child { margin-right: 0px; } }", ".framer-hsqPn.framer-v-x0p6i9 .framer-162hfqf { order: 1; }", ".framer-hsqPn.framer-v-x0p6i9 .framer-11gbeyb-container { order: 0; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 17
 * @framerIntrinsicWidth 64
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"GlS3sY0RA":{"layout":["auto","auto"]}}}
 * @framerVariables {"AgMT4QNLZ":"hideStatus"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerLuuHMz_1U: React.ComponentType<Props> = withCSS(Component, css, "framer-hsqPn") as typeof Component;
export default FramerLuuHMz_1U;

FramerLuuHMz_1U.displayName = "Status/Status Menu item";

FramerLuuHMz_1U.defaultProps = {height: 17, width: 64};

addPropertyControls(FramerLuuHMz_1U, {variant: {options: ["cX0MpctAV", "GlS3sY0RA"], optionTitles: ["Footer", "Nav"], title: "Variant", type: ControlType.Enum}, AgMT4QNLZ: {defaultValue: true, title: "Hide status", type: ControlType.Boolean}} as any)

addFonts(FramerLuuHMz_1U, [{explicitInter: true, fonts: [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZ1rib2Bg-4.woff2", weight: "600"}]}, ...StatusPulseFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})